import React from 'react';
import { Menu, MenuTrigger, MenuPopover, MenuList, MenuGroupHeader, MenuItem, ToolbarButton, Spinner } from '@fluentui/react-components';
import { ChannelArrowLeft28Regular, Person28Regular } from '@fluentui/react-icons';
import { TeamsFxContext } from '../../Context';
import { useLogout } from '../../../hooks/useLogout';
import { dialog } from "@microsoft/teams-js";
import { Constants } from '../Constants';
import { ReactComponent as DataPrivacyIcon } from "../../../Images/uiSettingsDataPrivacy24.svg";
import { AnalyticsManager } from '../../../services/analyticsUtility';
import AnalyticsConstants from "../AnalyticsConstants.json";
import { ApiResponse } from '../../../types/ApiRequest';

const LoginToolbar: React.FunctionComponent = () => {

  const teamsContext = React.useContext(TeamsFxContext);
  const i18n = teamsContext.i18n;
  const logoutMutation = useLogout();
  const [isLoggingOut, setIsLoggingOut] = React.useState(false);

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);
      const sessionId = teamsContext.teamcenter?.session?.sessionId || '';
      const userUid = teamsContext.teamcenter?.session?.userUid || '';
      await logoutMutation.mutateAsync({ sessionId, userUid });
      teamsContext.teamcenter.logout();
    } catch (error) {
      teamsContext.failure.setError(error as ApiResponse["error"]);
    } finally {
      setIsLoggingOut(false);
    }
  };

  const openDataPrivacyDialog =  () => {
    const analyticsManager = AnalyticsManager.getInstance();
    analyticsManager.logEvent(AnalyticsConstants.cmdDataPrivacy);
    const taskInfo = {
      title: i18n.DataPrivacyText,
      url: window.location.origin + "/index.html#/dataprivacy",
      card: null,
      size: { height: 600, width: window.innerWidth > 1000 ? 650: 350 },
    };
    dialog.url.open(taskInfo, () => teamsContext.queryClient.invalidateQueries());
  };

  const showDataPrivacy = () => {
    let retVal: boolean = false;
    const localConfiguration: any = window.localStorage.getItem("configuration");
    if (localConfiguration) {
      const configurationObject: any = JSON.parse(localConfiguration);
      if (configurationObject) {
        if (configurationObject[Constants.tenantTelemetryOptIn]) {
          const telemetryOptInValue: boolean = configurationObject[Constants.tenantTelemetryOptIn];
          if (telemetryOptInValue === true) {
            retVal = true;
          }
        }
      }
    }
    return retVal;
  }

  const getThemeClass = () => {
    let retVal: string = "data-privacy-icon-default";
    if (teamsContext.themeString) {
      if (teamsContext.themeString === 'dark') {
        retVal = "data-privacy-icon-dark";
      } else if (teamsContext.themeString === 'contrast') {
        retVal = "data-privacy-icon-contrast";
      }
    }
    return retVal;
  }

  return (
    <>
      {isLoggingOut && (
        <div className = "logout-spinner">
          <Spinner size="large" label = {i18n.LoggingOut}/>
        </div>
      )}
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <ToolbarButton aria-label="More" icon={<Person28Regular />} />
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            <MenuGroupHeader>
              {teamsContext.teamcenter.session?.userId}
            </MenuGroupHeader>
            {showDataPrivacy() ? (
              <div>
                <MenuItem className={getThemeClass()} icon={<DataPrivacyIcon/>} onClick={openDataPrivacyDialog} >
                  {i18n.DataPrivacyText}
                </MenuItem>
              </div>
            ) : null}
            <MenuItem icon={<ChannelArrowLeft28Regular />} onClick={handleLogout}>
              {i18n.LogoutTeamcenterText}
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    </>
  );
};

export default LoginToolbar;
