import React, { useEffect, useContext, useState } from "react";
import { TeamsFxContext } from "../Context";
import { useGetLoginUrl } from "../../hooks/useGetLoginUrl";
import { useLocation } from "react-router-dom";
import { Constants } from "../common/Constants";

// The popup used to login to Teamcenter
const TeamcenterAuthPopup: React.FunctionComponent = () => {

  const teamsContext = useContext(TeamsFxContext);

  const [userAadOid, setUserAadOid] = useState<string>('');

  teamsContext?.teamsUserCredential?.getUserInfo().then((userInfo: any) => {
    setUserAadOid(userInfo?.objectId);
  });


  // Used useLocation here to read the siteId that gets passed as query parameter in urlString in authenticationUtils
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  
  const selectedSite = searchParams.get(Constants.cacheSelectedSite);

  useEffect(() => {
    if (selectedSite) {
      localStorage.setItem(Constants.cacheSelectedSite, selectedSite);
    } else {
      localStorage.setItem(Constants.cacheSelectedSite, "");
    }
  }, [selectedSite]);
  

  const {isFetched, url} = useGetLoginUrl(userAadOid);

  useEffect(() => {
    if (url) {
      window.location.assign(url);
    }
  }, [isFetched, url]);

  return <></>;
};

export default TeamcenterAuthPopup;
