import React from "react";
import { logger } from "../../Logger";
import { Radio, RadioGroup } from "@fluentui/react-components";
import { Constants } from "../common/Constants";
import { TeamsFxContext } from "../Context";

interface ConfigurationProps {
  configuration: any,
  isFirst: boolean,
  updateDataPrivacy: (key: string, val: boolean) => void;
};

const ProductExcellenceProgram: React.FunctionComponent<ConfigurationProps> = ({configuration, isFirst, updateDataPrivacy}) => {
  const teamsContext = React.useContext(TeamsFxContext);
  const i18n = teamsContext.i18n;
  const getUserTelemetryDefaultValue = (consentKey: string) => {
    logger.logTrace(`Entered ${getUserTelemetryDefaultValue.name}`);
    let retVal: string = "";
    if (configuration) {
      if (consentKey in configuration) {
        const userTelemetryOptIn: any = configuration[consentKey];
        if (userTelemetryOptIn !== null) {
          if (userTelemetryOptIn === true) {
            retVal = Constants.accept;
          } else {
            retVal = Constants.decline;
          }
        } else {
          retVal = Constants.accept;
        }
      }
    }
    logger.logTrace(`Exit ${getUserTelemetryDefaultValue.name}`);
    return retVal;
  }
  
  return (
    <>
      <div id="pep_consent" className="data-privacy-consent-section">
        <h3>  {i18n.ProductExcellenceProgram} </h3>
        <p> {i18n.PepPara_1} </p>
        <p> {i18n.PepPara_2} </p>
        <p>
          {i18n.PepPara_3} {" "}
          <b>
            <a
              href="https://www.sw.siemens.com/en-US/trust-center/digital-product-experience/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.SiemensTrustCenter}
            </a>
          </b>
        </p>
        { isFirst ? 
          <div>
            <p> {i18n.PepAgreeConsent} </p>
            <p> {i18n.PepStopConsent} </p>
          </div>
          :
          <p> {i18n.PepChangeConsent} </p>
        }
        { isFirst ?
          <div>
          </div>
          :
          <div>
            <RadioGroup
              layout="horizontal"
              defaultValue={getUserTelemetryDefaultValue(
                Constants.userProductExcellenceTelemetryOptIn
              )}
            >
              <Radio
                id="rad_pep_accept"
                value="accept"
                label={i18n.AgreeText}
                onChange={() => updateDataPrivacy(Constants.userProductExcellenceTelemetryOptIn, true)}
              />
              <Radio
                id="rad_pep_decline"
                value="decline"
                label={i18n.DeclineText}
                onChange={() => updateDataPrivacy(Constants.userProductExcellenceTelemetryOptIn, false)}
              />
            </RadioGroup>
          </div>
        }
      </div>
    </>
  );
};

export default ProductExcellenceProgram;
