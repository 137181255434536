import {
    Dialog,
    DialogSurface,
    DialogTitle,
    DialogContent,
    DialogBody,
    Button,
    Image,
    Accordion,
    AccordionItem,
    AccordionHeader,
    AccordionPanel
  } from '@fluentui/react-components';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { TeamsFxContext } from '../Context';
import config from "../../config/config";
import { logger } from '../../Logger';

const TeamcenterError: React.FunctionComponent = () => {
  const teamsContext = useContext(TeamsFxContext);
  
  if(!teamsContext) {
    throw new Error('Teams context not defined.');
  }

  const i18n = teamsContext.i18n;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (teamsContext.failure?.error && teamsContext.failure.error.statusCode !== 401 && (teamsContext.failure.error.statusCode !== 403 || (teamsContext.failure.error.statusCode === 403 && teamsContext.failure.error.message === i18n.LicenseErrorMessage))) {
      setOpen(!!teamsContext.failure.error);
    }
  }, [i18n.LicenseErrorMessage, teamsContext.failure]);

  if(teamsContext.failure?.error) {
    logger.logError(JSON.stringify(teamsContext.failure?.error?.message))
  };

  return (
    <>
      <Dialog modalType="alert" open={open} onOpenChange={(_event, data) => setOpen(data.open)}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle className='text-center'>
              <span>{i18n.IssueText}</span>
            </DialogTitle>
            <DialogContent>
              <div className='text-center'>
                <div>
                  <Image src="error.png" className='image-width' />
                </div>
                <div>{i18n.ReloadText}</div>
                <div> {i18n.ReachOutText}: {teamsContext.failure?.error?.correlationId} </div><br/>
                {teamsContext.failure?.error && teamsContext.failure.error.statusCode === 403 && (
                  <div><b>{i18n.TcTeamsText}</b>{i18n.NotOnBoardedText}<a href="https://support.sw.siemens.com" target="_blank" rel="noopener noreferrer">{i18n.ContactUsText}</a></div>
                )}
                <br />
                <div className='error-buttons-container'>
                  <Button className='button-margin-right' onClick={() => setOpen(false)}>
                    {i18n.CloseText}
                  </Button>
                  <Button
                    className='button-margin-right'
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    {i18n.ReloadAppText}
                  </Button>
                </div>
              </div>
              {config.environment === 'development' && teamsContext.failure?.error && (
                <Accordion collapsible>
                  <AccordionItem value="1">
                  <AccordionHeader>{i18n.ErrorDetailsText}:</AccordionHeader>
                    <AccordionPanel>{i18n.CorrelationIDText}: {teamsContext.failure.error.correlationId}</AccordionPanel>
                    <AccordionPanel>{i18n.ErrorCodeText}:  {teamsContext.failure.error.statusCode}</AccordionPanel>
                    <AccordionPanel>{i18n.ErrorMessageText}: {teamsContext.failure.error.message}</AccordionPanel>
                  </AccordionItem>
                </Accordion>
              )}
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
};

export default TeamcenterError;
