import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  Button,
  Image,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  Spinner,
  Label,
  Dropdown,
  Option,
} from "@fluentui/react-components";
import * as React from "react";
import { useContext, useEffect } from "react";
import { TeamsFxContext } from "../Context";
import config from "../../config/config";
import { Constants } from "../common/Constants";
import { AuthCallResponse, Sites} from "../../types/CommonTypes";
import { AuthenticationUtils } from "./AuthenticationUtils";
import { Configuration } from "../../services/Configuration";
import { RequestUtils } from "../../services/RequestUtils";
import { ApiResponse } from "../../types/ApiRequest";

type Props = {
  children: React.ReactNode
};

// This is the dialog used to login from Teams to Teamcenter
const TeamcenterAuthentication: React.FC<Props> = ({children}) => {
  const teamsContext = useContext(TeamsFxContext);
  const { setError } = teamsContext.failure;
  const i18n = teamsContext.i18n;
  const [open, setOpen] = React.useState(false);
  const [isOnboarded, setIsOnboarded] = React.useState(false);
  const [checkingOnboardedStatus, setCheckingOnboardingStatus] = React.useState(true);
  const [siteOptions, setSiteOptions] = React.useState<Sites[]>([]);
  const [selectedSite, setSelectedSite] = React.useState<Sites | null>(null);
  const [onboardedStatusFailed, setOnboardedStatusFailed] = React.useState(false);

  // get onboarded status from TcTeamsApi
  // this will trigger an https request to TcTeams API on /onboardedstatus
  // the reponse will be 200 if onboarded, otherwise 403
  useEffect(() => {
    async function getOnBoardedStatus() {
      const response: ApiResponse = await RequestUtils.callTcTeamsApi(Constants.opgetOnboardedStatus, teamsContext.teamsUserCredential, undefined);
      if (response.error) {
        setError(response.error);
        setOnboardedStatusFailed(true);
      } else {
        if (response.data && response.data.length > 0) {
          setSiteOptions(response.data);
          setSelectedSite(response.data[0]);
        }
      }
      setCheckingOnboardingStatus(false);
    }
    getOnBoardedStatus();
  }, [setError, teamsContext.teamsUserCredential]);

  // Popup the login dialog if the user is not authenticated or the session has expired or the user is not authorized
  useEffect(() => {
    async function startProcessing() {
      if (teamsContext?.failure &&
        teamsContext?.failure.error &&
        teamsContext?.failure.error.statusCode === 403)
      {
        // if we get a 403, it means the tenant is not onboarded
        setIsOnboarded(false);
      }
      else if (
        (teamsContext?.failure &&
          teamsContext?.failure.error &&
          teamsContext?.failure.error.statusCode === 401)
      ) {
         const authorizerType: string = teamsContext?.configuration?.authorizerType;
         const configuration: any = Configuration.getValueFromLocalStorage(Constants.cacheTeamcenterCode);
         if(authorizerType === Constants.SAMAuthentication){
            let samTokenString : any = {
              accessToken :configuration[Constants.accessToken],
              refreshToken : configuration[Constants.refreshToken]
            };
            const retVal: AuthCallResponse = {
              sessionId: undefined,
              userId: undefined,
              userUid: undefined,
              error: undefined
            };
            teamsContext?.failure.setError(undefined);
            await AuthenticationUtils.initiateSAMEnabledLoginSSO(samTokenString, teamsContext?.teamsUserCredential, retVal);
            if (retVal.error) {
              teamsContext?.teamcenter?.setTCisAuthenticated(false);
              teamsContext?.failure.setError(retVal.error);
            } else if (
              retVal &&
              retVal.sessionId &&
              retVal.userId &&
              retVal.userUid
            ) {
              teamsContext?.teamcenter?.setTCisAuthenticated(true);
              teamsContext?.teamcenter?.setSession(retVal);
            } else {
              teamsContext?.teamcenter?.setTCisAuthenticated(false);
            }
          }
          else
          {
            setOpen(true);
            setIsOnboarded(true);  // if we have something else than a 403, the tenant is onboarded
          }
      } else if(!teamsContext?.teamcenter.isAuthenticated)
        {
          setOpen(true);
          setIsOnboarded(true);  // if we have something else than a 403, the tenant is onboarded
        }else {
        setOpen(false);
        setIsOnboarded(true); // if we have something else than a 403, the tenant is onboarded
      }
    }
    startProcessing();
  }, [
    teamsContext?.context,
    teamsContext?.failure,
    teamsContext?.teamcenter,
    teamsContext?.teamcenter.isAuthenticated,
    teamsContext?.configuration?.authorizerType,
    teamsContext?.teamsUserCredential
  ]);

  // Launch the login process
  const onLoginHandler = async () => {
    //Before login then clear local cache if exists.
    window.localStorage.removeItem(Constants.cacheTeamcenterCode);
    window.localStorage.removeItem(Constants.cacheConfiguration);
    window.localStorage.removeItem(Constants.cacheUserConfiguration);
    setOpen(false);
    if (selectedSite) {
      window.localStorage.setItem(Constants.cacheSelectedSite, JSON.stringify(selectedSite));
    }
    await teamsContext?.teamcenter.login();
    if (selectedSite) {
      window.localStorage.removeItem(Constants.cacheSelectedSite);
    }
    if (siteOptions.length > 0) {
      setSelectedSite(siteOptions[0]);
    }
  };

  return (
    <>
      <Dialog
        modalType="alert"
        open={open}
        onOpenChange={(_event, data) => setOpen(data.open)}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle className="text-center">
              {i18n.LoginIntoTeamcenter}
            </DialogTitle>
            <DialogContent>
              <div className="text-center">
                <div>
                  <Image src="hello.png" className="image-width" />
                </div>
                {!checkingOnboardedStatus && isOnboarded &&
                  <div>
                    <div className="login-popup">
                      {i18n.GetStartedText}
                    </div>
                    <div className="div-button-dropdown-label">
                      { siteOptions?.length>1 && (
                        <div className="div-label-dropdown">
                          <Label className="label-for-dropdown">
                            {i18n.SelectAnEnvironment}:
                          </Label>
                          <Dropdown
                            placeholder=""
                            value={selectedSite?.siteDisplay || siteOptions[0]?.siteDisplay || ""}
                            className="dropdown-for-environment"
                            listbox={{ className: "dropdown-listbox" }}
                            onOptionSelect={(event, data) => {
                              const selectedSite = siteOptions.find(
                                (site) => site.siteId === data.optionValue
                              );
                              setSelectedSite(selectedSite || null);
                            }}
                            disabled={
                              siteOptions.length === 0 ||
                              teamsContext?.teamcenter?.isAuthenticating ||
                              false
                            }
                          >
                            {siteOptions.map((option) => (
                              <Option key={option.siteId} value={option.siteId}>
                              {option.siteDisplay}
                            </Option>
                            ))}
                          </Dropdown>
                        </div>
                      )}
                    <Button
                      appearance="primary"
                      className="login-button-width"
                      onClick={onLoginHandler}
                      disabled={onboardedStatusFailed || teamsContext?.teamcenter?.isAuthenticating || false}
                    >
                      {teamsContext?.teamcenter?.isAuthenticating && (
                        <div className="flex">
                          <Spinner size="tiny" className="login-button" />
                        </div>
                      )}
                      {i18n.LoginTeamcenterText}
                    </Button>
                    </div>
                  </div>
                }
                {!checkingOnboardedStatus && !isOnboarded &&
                  <div><b>{i18n.Teamcenter}</b>{i18n.NotOnBoardedText}<a href="https://support.sw.siemens.com" target="_blank" rel="noopener noreferrer">{i18n.ContactUsText}</a></div>
                }
              </div>
              {config.environment === "development" &&
                teamsContext?.failure?.error && (
                  <Accordion collapsible>
                    <AccordionItem value="1">
                    <AccordionHeader>{i18n.ErrorDetailsText}:</AccordionHeader>
                      <AccordionPanel>{i18n.CorrelationIDText}: {teamsContext?.failure?.error?.correlationId}</AccordionPanel>
                      <AccordionPanel>{i18n.ErrorCodeText}:  {teamsContext?.failure?.error?.statusCode}</AccordionPanel>
                      <AccordionPanel>{i18n.ErrorMessageText}: {teamsContext?.failure?.error?.message}</AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                )}
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      {children}
    </>
  );
};

export default TeamcenterAuthentication;
