import { ModelObject } from "./TcSOATypes";

/**@class: Constants
 * @description: Defines all the constants values to be used in the frontend application.
 */
export class Constants {

  // Generic Constants
  public static readonly revision: string = "Revision";
  public static readonly thumbnail: string = "Thumbnail";
  public static readonly REF: string = 'REF';
  public static readonly opCrei: string = "crei";
  public static readonly tcXRTProperties: string = 'tc_xrt_properties';
  public static readonly LOVInterdependent: string = "Interdependent";
  public static readonly LicenseErrorMessage: string = "License for TeamsTc Application is not valid";

  // API operation Names
  public static readonly opConfiguration: string = "configuration";
  public static readonly opGetSessionanalyticsInfo: string = "sessionanalytics";
  public static readonly opUserConfiguration: string = "userconfiguration";
  public static readonly oploginSSO: string = "auth/loginsso";
  public static readonly opgetOnboardedStatus: string = "onboardedstatus";
  public static readonly opgetBaseChangeTypes: string = "changetype/getbasechangetypes"; 
  public static readonly getTypeConstantValues: string = "gettypeconstantvalues"; 
  public static readonly getTaskResults: string = "tasks";
  public static readonly getPreference: string = "getpreferences";
  public static readonly addParticipant: string = "participants/addparticipants";
  public static readonly removeParticipant: string = "participants/removeparticipants";
  public static readonly opGetFeedback: string = "summarize/feedback"; 
  public static readonly listObjects: string = "listobjects";
  public static readonly getDeclarativeStyleSheets: string = "stylesheet";

  // Cache constants
  public static readonly cacheTeamcenterCode: string = "teamcenterCode";
  public static readonly cacheConfiguration: string = "configuration";
  public static readonly cacheUserConfiguration: string = "userconfiguration";
  public static readonly cacheTeamcenterLanguage: string = "teamcenterLanguage";
  public static readonly cacheSelectedSite: string = "selectedSite";

  // Business Object and Properties Constants
  public static readonly problemReportRevision: string = "ProblemReportRevision";
  public static readonly simpleChangeRevision: string = "Cm0SimpleChangeRevision";
  public static readonly changeMaturity: string = "Change Maturity";
  public static readonly closure: string = "Closure";
  public static readonly cm0SimpleChange: string = "Cm0SimpleChange";
  public static readonly impactedItem: string = "Impacted Item";
  public static readonly problemItem: string = "Problem Item";

  // Configuration specific
  public static readonly problemReportType: string = "problemReportType";
  public static readonly openAIOptIn: string = "openAIOptIn";
  public static readonly problemReportFieldsDescription: string = "problemReportFieldsDescription";
  public static readonly authorizerType: string = "authorizerType";
  public static readonly samLoginUrl: string = "samLoginUrl";
  public static readonly accessToken: string = "accessToken";
  public static readonly refreshToken: string = "refreshToken";

  // Telemetry specific
  public static readonly tenantTelemetryOptIn: string = "tenantTelemetryOptIn";
  public static readonly userProductExcellenceTelemetryOptIn: string = "userProductExcellenceTelemetryOptIn";
  public static readonly userDigitalProductTelemetryOptIn: string = "userDigitalProductTelemetryOptIn";
  public static readonly accept: string = 'accept';
  public static readonly decline: string = 'decline';

  // Authentication Type
  public static readonly SAMAuthentication: string = "SAM";

  // Teamcenter Object Attributes specific
  public static readonly objectName: string = "object_name";
  public static readonly objectString: string = "object_string";
  public static readonly itemId: string = "item_id";
  public static readonly imageTicket: string = "awp0ThumbnailImageTicket";

  // Create Change specific
  public static readonly types: string = "Types";

  // Problem Reports specific
  public static readonly problemReport: string = "ProblemReport";
  public static readonly allProblems: string = "All";
  public static readonly openProblems: string = "Open";
  public static readonly closedProblems: string = "Closed";

  // Object Constants
  public static readonly dummyBusinessObject: ModelObject = {
    uid: "AAAAAAAAAAAAAA",
    type: "unknownType",
    className: "",
    objectId: "",
  };

  // Workflow Tasks specific
  public static readonly propAwp0ProcessTemplates: string = 'awp0ProcessTemplates';
  public static readonly dueDate: string = "Due date";
  public static readonly startDate: string = "Start date";
  public static readonly dueDatePropName: string = "due_date";
  public static readonly startDatePropName: string = "fnd0StartDate";
  public static readonly workflowTask: string = "WorkflowTask";
  public static readonly taskDetailsUrl: string = "/index.html#/workflowtaskinfo/";
  public static readonly sortOrder: string = "DESC";
  public static readonly comments: string = "comments"
  public static readonly signoff: string = "Signoff";
  public static readonly EPMPerformSignoffTask: string = "EPMPerformSignoffTask";
  public static readonly EPMDoTask: string = "EPMDoTask";
  public static readonly EPMSelectSignoffTask: string = "EPMSelectSignoffTask";
  public static readonly EPMConditionTask: string = "EPMConditionTask";
  public static readonly SOA_EPM_approve_action: string = "SOA_EPM_approve_action";
  public static readonly SOA_EPM_approve: string = "SOA_EPM_approve";
  public static readonly SOA_EPM_reject_action: string = "SOA_EPM_reject_action";
  public static readonly SOA_EPM_reject: string = "SOA_EPM_reject";
  public static readonly SOA_EPM_complete_action: string = "SOA_EPM_complete_action";
  public static readonly SOA_EPM_completed: string = "SOA_EPM_completed";
  public static readonly Complete: string = "Complete";

  // Participant Constants
  public static readonly addParticipantId: string = "AddParticipant";
  public static readonly removeParticipantId: string = "RemoveParticipant";
  
}