import { ModelObject } from "../components/common/TcSOATypes"

/**@interface OpenAIInput
 * @description Interface to represent the input request structure for open AI endpoint interaction.
 * @member rawTranscript {string} <input> raw content text for summarization.
 * @member prType {string} <input> type of problem report BO.
 * @member prSchema {string[]} <input> array of internal property names.
 */
export interface OpenAIInput {
  rawTranscript: string,
  prType: string,
  prSchema: string[]
}

/**@interface FeedbackInput
 * @description Interface to represent the input request structure for feedback API.
 * @member sessionID {string} <input> sessionId received from summarize API.
 * @member correctionForm {string} <input> form object, with keys that are same as the prSchema.
 * @member userFeedback {object} <input> object containing respective feedback button input.
 */
export interface FeedbackInput {
  sessionID: string,
  correctionForm: string,
  UserFeedback: UserFeedbackInput
}

/**@interface UserFeedbackInput
 * @description Interface to represent the input request structure for userFeedback.
 * @member PositiveUserFeedback {boolean} <input> true for thumbs up feedback.
 * @member OptionalFeedback {string} <input> optional textual feedback from user.
 */
export interface UserFeedbackInput {
  PositiveUserFeedback?: boolean,
  OptionalFeedback?: string
}

/**@interface ErrorMessage
 * @description Interface to represent the error messages on the UI.
 * @member title {string} title of the error.
 * @member message {any} error message.
 * @member statusCode {any} status code of the error.
 * @member correlationId {string} correlation id of the error.
 * @member strackTrace {any} {optional} stack trace for the error.
 */
export interface ErrorMessage {
  title: string,
  message: string,
  statusCode: any,
  correlationId: string,
  strackTrace?: any,
}

/**@enum OperationName
 * @description Enumeration to represent the various type of operation on the Panel.
 */
export enum OperationName {
  CREATE    = 'Create',
  EDIT      = 'Edit',
}


/**@interface SortCriteriaNameValuePairs
 * @description Interface to represent the criteria to use to sort the results.
 * @member sortField {string}
 * @member sortOrder {string}
 */
export interface SortCriteriaNameValuePairs {
  sortField: string,
  sortOrder: string
}

/**@interface ListObjectRequestData
 * @description Interface to represent the input structure for the listObjects API call.
 * @member ObjectType {string} 
 * @member SearchCriteria {any} {optional}
 * @member SortCriteriaNameValuePairs {SortCriteriaNameValuePairs} {optional}
 */
export interface ListObjectRequestData {
  ObjectType: string,
  SearchCriteria?: {},
  SortCriteriaNameValuePairs?: SortCriteriaNameValuePairs,
  FilterPropNameValuePairs?: {},
  MaxToLoad: number,
  StartIndex: number
}

/**@interface AllowedChangeType
 * @description Interface to represent the structure of allowed change type.
 * @member typeName {string} allowed type internal name
 * @member typeDisplayName {string} allowed type display name
 */
export interface AllowedChangeType {
  typeName: string;
  typeDisplayName: string;
}

/**
 * @interface Attachment
 * @description Interface to represent the structure for the attachment.
 * @member uid {string} represents the uid of the item.
 * @member name {string} represents the name of the item.
 * @member imageTicket {string} represents the image ticket of the item.
 */
export type Attachment = {
  uid: string;
  name: string;
  imageTicket: string;
}

/**
 * @interface SAMConfig
 * @description Interface to represent the structure for the SAM configuration.
 * @member authorizerType {string} represents the authorization type.
 * @member SAMEnabled {boolean} represents if the Teamcenter is SAM enabled or not.
 * @member samLoginUrl {string} represents the SAM Auth login if it SAMEnabled is true.
 */
export type SAMConfig = {
  authorizerType: string,
  SAMEnabled: boolean,
  samLoginUrl: string
}

/**
 * @type Sites
 * @description Type to represent the structure for the Site.
 * @member siteId {string} represents the site ID.
 * @member siteDisplay {string} represents the site display name.
 */
export type Sites = {
  siteId: string;
  siteDisplay: string;
};

/**
 * @interface AuthCallResponse
 * @description Interface to represent the structure of Authentication response.
 * @member sessionId {string | undefined} represents the id of the login session.
 * @member userId {string | undefined} represents the user id of the logged in user.
 * @member userUid {string | undefined represents the object uid of the logged user.
 * @member error {IErrorMessage} represents the error in case of login failure/exceptions.
 * @member accessToken {string} represents access token from the loginSSO response.
 * @member isTokenRefreshed {string} represents if the access token is refreshed.
 * @member refreshToken {string} represents refresh token form the loginSSO response.
 * @member analyticsInfo {any} structure having analytics information.
 * @member ecaId {string} represents the eca id.
 */
export interface AuthCallResponse {
  sessionId: string | undefined,
  userId: string | undefined,
  userUid: string | undefined,
  accessToken?: string,
  isTokenRefreshed?: boolean,
  refreshToken?: string,
  ecaId?: string,
  analyticsInfo?: any,
  error: IErrorMessage,
  locale?: String,
  siteId?: String,
  siteDisplay?: String
}


/**@interface ParticipantObject 
 * @description represents Teamcenter object with display value.
 * @member displayValue {string} Participant display value.
 */
export interface ParticipantObject extends ModelObject {
  displayValue: string;
}

/**@interface participantObject 
 * @description represents participant object.
 * @member typeName {string} Type of Participant object present in Teamcenter.
 * @member multipleAssignee {boolean} Multiple Assignee allowed.
 * @member assigneeList {[Participant]} List of Assignee.
 */
export interface ParticipantTypeObject {
  typeName : string,
  displayName : string,
  multipleAssignee : boolean,
  assigneeList : ParticipantObject[],
}

/**@interface AddParticipantInputStructure
 * @description represents the input structure for Add Participant API call.
 * @member wso {ModelObject} ModelObject.
 * @member participantInputData [{assignee:ModelObject,participantTypeL:string}] object uid.
 */
export interface AddParticipantInputStructure {
  wso: ModelObject,
  participantInputData: [{
    assignee: ModelObject,
    participantType: string
  }]
}

/**@interface RemoveParticipantInputStructure
 * @description represents the input structure for Remove Participant API call.
 * @member participants {[{itemRev:ModelObject,participant:ModelObject[]}]} Participant type structure.
 */
export interface RemoveParticipantInputStructure {
  "participants": [{
    "itemRev": ModelObject,
    "participant": ModelObject[]
  }]
}

export type IListObjectRequestData = ListObjectRequestData | undefined;
export type IErrorMessage = ErrorMessage | undefined;
export type ISAMConfig = SAMConfig | undefined;
export type IAuthCallResponse = AuthCallResponse | undefined;
